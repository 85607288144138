import 'core-js/stable'
import 'regenerator-runtime/runtime'
import $ from 'jquery'
global.$ = $
global.jQuery = $

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
// require("jquery")
import 'bootstrap'

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip();
//   $("#menu-toggle").click(function(e) {
//     e.preventDefault();
//     $("#wrapper").toggleClass("toggled");
//     $(".drawer-button").toggleClass("rotated");
//   });
// })
require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")